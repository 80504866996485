<template>
<div> <h2  style="color:red">LAB!! PROJEKT ÜBERSICHT</h2>

  <div class="container" id="Project_Header">
    <br />
    <h3 align="center">Aktuell verfügbare Projekte</h3>
    <br />
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-6">
            <h3 class="panel-title">Sample Data</h3>
          </div>
          <div class="col-md-6" align="right">
            <input type="button" class="btn btn-success btn-xs" @click="openModel" value="Add" />
          </div>
        </div>
      </div>
      <div class="panel-body">
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              <th>PL</th>
              <th >Description</th>
              <th>Open</th>
              <th>Unassign</th>
            </tr>
            <tr v-for="row in allData" v-bind:key="row">
              <td>{{ row.projectNo }}</td>
              <td>{{ row.projectName }}</td>
              <td>{{ row.projectStart }}</td>
              <td>{{ row.projectEnd }}</td>
              <td>{{ row.projectLeader }}</td>
              <td>{{ row.projectDesc }}</td>
              <td><router-link :to="{path: '/workpackages',  query:{projectNo: row.projectNo}}" class="nav-link">Open Project</router-link></td>

              <td><button type="button" name="edit" class="btn btn-primary btn-xs edit" @click="fetchData(row.ID)">Edit</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
 </div>



  <div>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content"
      :attach="true"
      :lock-scroll="false">
      <button class="modal__close" @click="showModal = false">

      </button>
      <span class="modal__title">Hello, vue-final-modal</span>
      <div class="modal__content">
        <p>
          Vue Final Modal is a renderless, stackable, detachable and lightweight modal component.
        </p>
      </div>
      <div class="modal__action">
        <button @click="showModal = false">confirm</button>
        <button @click="showModal = false">cancel</button>
      </div>
    </vue-final-modal>
    <button id="clickbutton" @click="showModal = true">Open modal</button>
  </div>



<!--      <div v-if="myModel === true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">{{ dynamicTitle }}</h4>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label>Enter First Name</label>
                  <input type="text" class="form-control" v-model="projectNo" />
                </div>
                <div class="form-group">
                  <label>Enter Last Name</label>
                  <input type="text" class="form-control" v-model="projectName" />
                </div>
                <br />
                <div align="center">
                  <input type="text" v-model="hiddenId" />
                  <input type="button" class="btn btn-success btn-xs" v-model="actionButton" @click="submitData" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
              </div>
            </div>
          </div>
      </div>-->


</div>
  </template>

<script>

const axios = require('axios').default;

// axios.<method> will now provide autocomplete and parameter typings

  import { VueFinalModal  } from 'vue-final-modal'

  export default {

name: "Project_HeaderLab",
components: {VueFinalModal
},


  data: function () {
    return {allData:{}, myModel:false, actionButton:'Insert', dynamicTitle:'Add Data', projectNo:'', projectName:'', showModal:false};
  },
  methods: {

    openModalExample() {
      this.$vfm.show('example')
    },


    fetchAllData:function() {
      axios.post('/src/php/sustamizer_collab_manage_project_entry.php', {
        action:'fetchall'
      }).then((response)=>{
        this.allData=response.data;
      });
    },

    openModel:function(){
      this.projectNo = '';
      this.projectName = '';
      this.actionButton = "Insert";
      this.dynamicTitle = "Add Data";
      this.showModal = true;
    },
    submitData:function(){
      if(this.projectNo != '' && this.projectName != '')
      {
        if(this.actionButton == 'Insert')
        {
          axios.post('/src/php/sustamizer_collab_manage_project_entry.php', {
            action:'insert',
            projectNo:this.projectNo,
            projectName:this.projectName
          }).then(function(response){
            this.myModel = false;
            this.fetchAllData();
            this.projectNo = '';
            this.projectName = '';
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
        if(this.actionButton == 'Update')
        {
          axios.post('/src/php/sustamizer_collab_manage_project_entry.php', {
            action:'update',
            projectNo : this.projectNo,
            projectName: this.projectName,
            hiddenId : this.hiddenId
          }).then(function(response){
            this.myModel = false;
            this.fetchAllData();
            this.projectNo = '';
            this.projectName = '';
            this.hiddenId = '';
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
      }
      else
      {
        alert("Fill All Field");
      }
    },
    fetchData:function(id){
      let self = this
      axios.post('/src/php/sustamizer_collab_manage_project_entry.php', {
        action:'fetchSingle',
        id:id
      }).then(function(response){
        self.projectNo = response.data.projectNo;
        self.projectName = response.data.projectName;
        self.hiddenId = response.data.ID;
         self.mouseY = '-'+event.clientY+'px';
 self.myModel = true;
        self.actionButton = 'Update';
        self.dynamicTitle = 'Edit Data';
      });
    },
    deleteData:function(id){
      if(confirm("Are you sure you want to remove this data?"))
      {
        axios.post('/src/php/sustamizer_collab_manage_project_entry.php', {
          action:'delete',
          id:id
        }).then(function(response){
          this.fetchAllData();
          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
    }
  },
  created:function(){
    this.fetchAllData();
  }

 }
</script>

<style scoped>

/*  NEW MODAL   */


::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}


  .dark-mode div::v-deep .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
  }





table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}



</style>