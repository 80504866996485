<template>
<div>


  <div class="container" id="Workpackage_Header">
    <h2>WORKPACKAGE OVERVIEW</h2>
    <br />
    <h3 align="center">Hallo {{ $auth.user.name }}, your currently assigned workpackages</h3>
    <h3  align="center">Your Role: "<i style="color:red">{{userRole}}"</i></h3>
    <br />
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-6">
          </div>
          <div class="col-md-6" align="right">
           <input v-if="userRole==='admin' || userRole==='projectmanager'  " type="button" class="btn btn-info btn-xs" value="Add WP" @click="openModelWP"  style="margin-right: 10px"/>
            <input type="button" class="btn btn-success btn-xs" @click="fetchAllDataWP" value="Search" />
          </div>
        </div>
      </div>
      <div class="panel-body">
        <div>
          <table class="table table-hover table-sm table-bordered">
            <tr >
              <th col-sm-1>No.</th>
              <th col-sm-1>Pic</th>
              <th col-sm-2>Name</th>
              <th col-sm-2>Reference</th>
              <th col-sm-1>Start</th>
              <th col-sm-1>End</th>
              <th col-sm-1>Owner</th>
              <th col-sm-3>Description</th>
              <th col-sm-1>Open</th>
              <th col-sm-1>Actions</th>
            </tr>
            <tr   v-for="row in allDataWP" v-bind:key="row">
              <td col-sm-1>{{ row.wpID }}</td>
              <td col-sm-1><img :src='row.wpImgPath1' width="100px" height="auto" alt="partpic"   style="padding-right:5px"  @click="showImageModal(row.wpImgPath1, row.partName)"/></td>
              <td col-sm-2>{{ row.wpName }}</td>
              <td col-sm-2>{{ row.wpRef }}</td>
              <td col-sm-1>{{ row.wpStart }}</td>
              <td col-sm-1>{{ row.wpEnd }}</td>
              <td v-if="userRole != 'client'"  col-sm-1>{{ row.wpOwner }}</td>
              <td v-if="userRole == 'client'"  col-sm-1>Team</td>
              <td col-sm-3>{{ row.wpDesc }}</td>

              <td col-sm-1 v-if="row.wpStatus=='working' && userRole != 'client'"><router-link :to="{path: '/workpackageSCA',  query:{wpID: row.wpID, wpName: row.wpName, projectNo: row.projectNo, wpHash:row.wpHash, wpStatus:row.wpStatus, userRole: userRole  }}" class="btn btn-primary" style="color:black">Open</router-link></td>
              <td col-sm-1 v-if="row.wpStatus=='assigned' && userRole != 'client'"><button type="button " name="edit" class="btn btn-outline-info" @click="assignWP(row.wpID)">Assigned</button></td>
              <td col-sm-1 v-if="row.wpStatus=='qualitycheck' && userRole != 'client'"><button type="button " name="edit" class="btn btn-outline-danger">In QS</button></td>
              <td v-if="row.wpStatus=='qualitycheck' && userRole != 'client'"><router-link :to="{path: '/workpackageSCA',  query:{wpID: row.wpID, wpName: row.wpName, projectNo: row.projectNo, wpHash:row.wpHash, wpStatus:row.wpStatus, userRole: userRole  }}" class="btn btn-primary" style="color:black">Open</router-link></td>
              <td col-sm-1 v-if="row.wpStatus=='released'"><button type="button " name="edit" class="btn btn-outline-success">Released</button></td>
              <td v-if="row.wpStatus=='released'" ><router-link :to="{path: '/workpackageSCA',  query:{wpID: row.wpID, wpName: row.wpName, projectNo: row.projectNo, wpHash:row.wpHash, wpStatus:row.wpStatus, userRole: userRole  }}" class="btn btn-primary" style="color:black">Open</router-link></td>
              <td col-sm-1 v-if="row.wpStatus=='waiting' && userRole != 'client'"><button type="button " name="edit" class="btn btn-outline-secondary" style="color:black">Waiting</button></td>

              <td col-sm-1 v-if="row.wpStatus=='assigned'&& userRole != 'client'"><router-link :to="{path: '/project',  query:{wpID: row.wpID, wpName: row.wpName, projectNo: row.projectNo, wpHash:row.wpHash, userRole: userRole  }}" class="nav-link" style="color:black">Reload</router-link></td>
              <td col-sm-1 v-if="row.wpStatus=='qualitycheck' && userRole=='qamanager' && userRole != 'client'"><router-link :to="{path: '/workpackageSCA',  query:{wpID: row.wpID, wpName: row.wpName, projectNo: row.projectNo, wpHash:row.wpHash, wpStatus:row.wpStatus, userRole: userRole }}" class="btn btn-primary" style="color:black">run QS</router-link></td>
              <td col-sm-1  v-if="(userRole==='admin' || userRole==='projectmanager') &&  row.wpStatus!='qualitycheck' && userRole != 'client'" ><button type="button" class="btn btn-secondary btn-xs" value="Edit WP" @click="fetchDataWP(row.wpHash, row.wpID)"  style="color:black">Edit</button></td>
              <td col-sm-1  v-if="(userRole =='client' && row.wpStatus=='released') || userRole != 'client'" ><button type="button" class="btn btn-info btn-xs" value="Create Report" @click="createReport(row.wpHash, row.wpID)" >Report</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
 </div>

  <div v-if="myModel">
    <transition name="model">
      <div class="modal-mask" v-bind:style="{top: mouseY}" >
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">{{ dynamicTitle }}</h4>
              </div>
              <div class="modal-body">
                <div>
                  <div v-if="!successAlert && previewImage"
                       class="imagePreviewWrapper"
                       :style="{ 'background-image': `url(${previewImage})` }"
                       @click="selectImage">
                  </div>
                  <div v-if="successAlert && previewImage"
                       class="imagePreviewWrapper"
                       :style="{ 'background-image': `url(${previewImage})` }"
                  >
                  </div>


                  <input v-if="!successAlert"
                         ref="file"
                         type="file"
                         id = "file"
                         v-on:change="handleFileUpload()"
                         @input="pickFile" />
                </div>
                <button v-if="!successAlert" v-on:click="submitFile()">SAVE PICTURE</button>


                <div class="form-group">
                  <label>Workpackage Name</label>
                  <input type="text" class="form-control" v-model="wpName" />
                </div>
                <div class="form-group">
                  <label>Workpackage Ref-ID</label>
                  <input type="text" class="form-control" v-model="wpRef" />
                </div>
                <div  class="form-group">
                  <label>Workpackage Number (ID will be completed)</label>
                  <input type="text" class="form-control" v-model="wpID" />
                </div>
                <div  class="form-group">
                  <label>System No. (e.g. WPSYS0030)</label>
                  <input type="text" class="form-control" v-model="systemID" />
                </div>
                <div v-if="dynamicTitle == 'Edit Data'" class="form-group">
                  <label>Workpackage Number</label>
                  <input type="text"  class="form-control" :value="wpID" v-model="wpID" readonly/>
                  <label>Workpackage Hash</label>
                  <input type="text"  class="form-control" :value="wpHash" v-model="wpHash" readonly/>
                </div>
                <div class="form-group">
                  <label>Start Date</label>
                  <Datepicker v-model="wpStart"  ></Datepicker>
                  <span>datum:  {{wpStart}}</span>
                </div>
                <div class="form-group">
                  <label>End Date</label>
                  <Datepicker  v-model="wpEnd"></Datepicker>
                  <span>datum ende :  {{wpEnd}}</span>
                </div>

                <div class="form-group" style="margin-top: 15px">
                  <select class="form-select"  aria-label="multiple select example" v-model="wpOwner">
                    <option disabled>Select WP Owner</option>
                    <option>Zoltan Sakal</option>
                    <option>Oliver Homrighausen</option>
                    <option>Eric Jiang</option>
                    <option>Peter Scheyrer</option>
                    <option>Johann Buettner</option>
                    <option>Andreas Rennet</option>
                    <option>Rainer Buecker</option>
                  </select>
                </div>
                <div class="form-group" style="margin-top: 15px">
                  <label>Description</label>
                  <textarea type="text" class="form-control"  rows="3"  v-model="wpDesc"> </textarea>
                </div>
                <div class="form-group" style="margin-top: 15px">
                  <label style="font-weight: bold; color:blue">Current Working Status:  {{wpStatus}} </label><br>
                  <div class="form-check">
                    <input class="form-check-input" name="assigned" type="radio" value="assigned" id="wpStatus1" checked="checked" v-model="wpStatus">
                    <label class="form-check-label" for="wpStatus1">
                      Set WP immediately to "assigned" to Owner
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" name="waiting" type="radio" value="waiting" id="wpStatus2"  v-model="wpStatus">
                    <label class="form-check-label" for="wpStatus2">
                      Set WP to "waiting" and assign it later
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" name="stopped" type="radio" value="stopped" id="wpStatus3" v-model="wpStatus">
                    <label class="form-check-label" for="wpStatus3">
                      Set WP to "stopped" this WP can only be set back from admin
                    </label>
                  </div>
                </div>

                <br/>
                <div align="center">
                  <input type="hidden" v-model="hiddenId" />
                  <input type="hidden" v-model="wpHash" />
                  <input type="button" class="btn btn-success btn-xs" v-model="actionButton" @click="submitDataWP" />
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</div>
  </template>


<script>

import Datepicker from 'vuejs-datepicker';


const axios = require('axios').default;

// axios.<method> will now provide autocomplete and parameter typings

export  default {
  name: "Workapackage_Header",
  components: { Datepicker},
  props: {
    namespace: Array,

  },

  data: function () {
    return {
      allDataWP:{}, myModel:false, actionButton:'Insert', dynamicTitle:'Add Workpackage', wpID:'', systemID:'', wpName:'', wpHash:'', wpStatus:  this.$route.query.wpStatus,
    projectNo:this.$route.query.projectNo, userRole:'', wpImgPath1:'', wpImgPath2: '' , wpOwner:'', wpStart:'', wpEnd:"", wpRef:'',
      previewImage: null, file:'',  successAlert:false, errorAlert:false,  uploadedImage:'', imgName:'' , errorMessage: '', successMessage:'',
      mouseY: 0
    };
  },
  methods: {
    // SELECT IMAGE
    selectImage () {
      this.$refs.file.click()
    },
    pickFile () {
      let input = this.$refs.file
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },


    //  Save IMAGE
    submitFile(){
      this.file = this.$refs.file.files[0];

      var formData = new FormData();

      formData.append('file', this.file);

      axios.post('./src/php/sustamizer_collab_image_upload.php', formData, {
        header:{
          'Content-Type' : 'multipart/form-data'
        }
      }).then((response)=>{
        if(response.data.image == '')
        {
          this.errorAlert = true;
          this.successAlert = false;
          this.errorMessage = response.data.message;
          this.successMessage = '';
          this.uploadedImage = '';
        }
        else
        {
          this.errorAlert = false;
          this.successAlert = true;
          this.errorMessage = '';
          this.successMessage = response.data.message;
          let image_html = "<img src='"+response.data.image+"' class='img-thumbnail' width='200' />";
          this.imgName = response.data.image;
          this.uploadedImage = image_html;
          this.$refs.file.value = '';
        }
      });
    },

    /*
      Handles a change on the file upload
    */
    handleFileUpload(){
      this.file = this.$refs.file.files[0];

    },


    showImageModal: function(imgName, partName) {
      this.dynamicTitlePic = partName;
      this.partPath = imgName;
      this.myModelPic = true;
    },
    closeImageModal: function() {

      this.myModelPic = false;
    },


    assignWP:function(wpID) {
      {
        axios.post('/src/php/sustamizer_collab_manage_workpackages.php', {
          action: 'assignWP',
          wpID: wpID,
        }).then(function () {
          alert('The workpackage has been accepted, and will be available through the project search in some seconds');
        });
      }

    },

       //  MANAGE WP   //

    openModelWP:function(){
      this.previewImage =false;
      this.wpID = '';
      this.systemID = '';
      this.wpName = '';
      this.wpRef = '';
      this.wpDesc = '';
      this.wpStatus = '';
      this.actionButton = "Insert";
      this.dynamicTitle = "Add Data";
      this.mouseY = event.clientY+'px';
      this.myModel = true;
    },

    // MANAGE WPs
    fetchAllDataWP:function() {
      axios.post('/src/php/sustamizer_collab_manage_workpackages.php', {
        action:'fetchallWP' , projectNo: this.$route.query.projectNo, ceID : this.$parent.$auth.user.sub, userRole : 'admin'
      }).then((response)=>{
        this.allDataWP=response.data;
      });
    },
    submitDataWP:function(){
      if(this.wpID != '' && this.wpName != '')
      {
        if(this.actionButton == 'Insert')
        {
          axios.post('/src/php/sustamizer_collab_manage_workpackages.php', {
            action:'insertWP',
            projectNo: this.$route.query.projectNo,
            wpID:this.wpID,
            systemID:this.systemID,
            wpName:this.wpName,
            wpRef:this.wpRef,
            wpStatus: this.wpStatus,
            wpStart: this.wpStart,
            wpEnd: this.wpEnd,
            wpOwner: this.wpOwner,
            wpDesc: this.wpDesc,
            // Path > only imgName Necessar
            wpImgPath1: this.imgName,
            wpImgPath2: this.imgName,
            ceID : this.$parent.$auth.user.sub
          }).then((response)=>{
            this.myModel = false;
            this.fetchAllDataWP();

            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
        if(this.actionButton == 'Update')
        // console.log ('UDPATE wpHash:'+this.wpHash)
        {
          axios.post('/src/php/sustamizer_collab_manage_workpackages.php', {
            action:'updateWP',
            wpID : this.wpID,
            systemID: this.systemID,
            wpHash: this.wpHash,
            projectNo: this.projectNo,
            wpName : this.wpName,
            wpRef: this.wpRef,
            wpImgPath1: this.imgName,
            wpImgPath2 : this.imgName,
            wpStart: this.wpStart,
            wpEnd : this.wpEnd,
            wpOwner: this.wpOwner,
            ceID : this.ceID,
            wpDesc: this.wpDesc,
            wpStatus: this.wpStatus


          }).then((response)=>{
            this.myModel = false;
            this.fetchAllDataWP();

            if ( response.data.message ) {
              // alert(response.data.message);
            //  response.data.message = '';
              this.successAlert = false;
            }
          });


        }
      }
      else
      {
        alert("Fill All necessary Fields");
      }
    },
    fetchDataWP:function(wpHash){
      let self = this
      console.log ('wpID:' + wpHash)
      axios.post('/src/php/sustamizer_collab_manage_workpackages.php', {
        action:'fetchSingleWP',
        wpHash:wpHash

      }).then(function(response){
        self.wpID = response.data.wpID;
        self.systemID = response.data.systemID;
        self.wpHash = response.data.wpHash;
        self.projectNo = response.data.projectNo;
        self.wpName = response.data.wpName;
        self.wpRef = response.data.wpRef;
        self.wpImgPath1 = response.data.wpImgPath1;
        self.previewImage = response.data.wpImgPath1;
        self.imgName = response.data.wpImgPath1;
        self.wpImgPath2 = response.data.wpImgPath2;
        self.wpStart = response.data.wpStart;
        self.wpEnd = response.data.wpEnd;
        self.wpOwner = response.data.wpOwner;
        self.ceID = response.data.ceID;
        self.wpDesc = response.data.wpDesc;
        self.wpStatus = response.data.wpStatus;
        self.mouseY = '-'+event.clientY+'px';
        self.myModel = true;
        self.actionButton = 'Update';
        self.dynamicTitle = 'Edit Data';

      });
    },
    deleteDataWP:function(id){
      if(confirm("Are you sure you want to remove this data?"))
      {
        axios.post('/src/php/sustamizer_collab_manage_workpackages.php', {
          action:'deleteWP',
          id:id
        }).then(function(response){
          this.fetchAllDataWP();
          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
    },
    createReport:function(wpHash, wpID){
      if(confirm("A excel based report will be created, this might take a while"))
      {
        axios.post('/src/php/sustamizer_collab_excel_report.php', {
          action:'createXLSReport',
          wpHash:wpHash,
          wpID: wpID,
          projectNo: this.projectNo,
          userRole : this.userRole,
          userMail : this.namespace['email'],
          userName : this.namespace['name'],
          userMail2 : this.namespace['https://www.sustamizer.net/mail2']
        }).then(function(response){

             alert(response.data.message);

        });
      }
    }
  },

  mounted:function() {
    {
        if (this.wpStatus == "qualitycheck") {
          if (confirm("Are you sure you want to send the WP to Quality Check?")) {
          axios.post('/src/php/sustamizer_collab_manage_workpackages.php', {
            action: 'startQAWP',
            wpID: this.$route.query.wpID
          }).then(()=>{
            alert('The workpackage has been send to QA, you will receive an information, if the WP has passed the QA or if rework is necessary');
            this.userRole = 'admin'
            this.fetchAllDataWP();

          });
        }
    }
      else if (this.wpStatus == "released") {
        if (confirm("Are you sure you want to send the WP to RELEASED status?")) {
          axios.post('/src/php/sustamizer_collab_manage_workpackages.php', {
            action: 'released',
            wpID: this.$route.query.wpID
          }).then(()=>{
            alert('The workpackage has been set to RELEASED, unlocking the this status can only be requested from Admin');
            this.userRole = 'admin'
            this.fetchAllDataWP();

          });
        }
      }
        else if (this.wpStatus == "qsfailed") {
          if (confirm("Are you sure you want to send the WP back to Rework?")) {
            axios.post('/src/php/sustamizer_collab_manage_workpackages.php', {
              action: 'qsfailed',
              wpID: this.$route.query.wpID
            }).then(()=>{
              alert('The workpackage has been sent back to rework based on the QA comments. A new QS Check has has to be started from Cost Engineer');
              this.userRole = 'admin'
              this.fetchAllDataWP();

            });
          }
        }
       else {

          this.userRole = 'admin'
          this.fetchAllDataWP();
        }
    }
  },


}
</script>

<style scoped>

table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}
tr {
  font-size: small !important;
}

.imagePreviewWrapper
{
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}



  .modal-mask {
   position: absolute;
   z-index: 9998 !important;
   left: 10%;
   top: 100px;
   transform: translate(0px, 0px);
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, .5);
   display: table;
   transition: opacity .3s ease;
   font-size: medium;
   overflow-y: scroll;
 }

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  font-size: small;
}

</style>