<template>
  <div>


    <div class="accordion accordion-flush" :id="compUID">
      <div class="accordion-item">
        <div class="row justify-content-start">
          <div class="col-11" >
            <h2 class="accordion-header" :id="mhcompUID">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="mchcompUID" aria-expanded="false" :aria-controls="mccompUID" style="background-color: #F2AF1D; color: #E7E9DF">
                <div v-if="userRole != 'costengineer' "  >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="margin-right:10px" fill="currentColor" class="bi bi-droplet-half" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10c0 0 2.5 1.5 5 .5s5-.5 5-.5c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"/>
                  <path fill-rule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448z"/>
                </svg>
                </div>

                Commodities and Consumables  [<strong>Total Commodity Footprint: {{totalCommodityCost}} kgCO2e</strong>]

               </button>
            </h2>
          </div>
          <div class="col-1 my-auto" style="display:flex; justify-content: space-between">
            <button v-if="userRole!= 'client' && wpStatus == 'working'"  type="button" class="btn btn-link btn-sm" @click="openModelCommodity(procID)" value="Add" data-bs-toggle="tooltip" data-bs-placement="top" title="Add Commodity">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkgreen" class="bi bi-plus-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </button>
            <button v-if="$store.state.clipboard.commodityID != '' && userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="pasteCommodity($store.state.clipboard.commodityID)" value="Paste" data-bs-toggle="tooltip" data-bs-placement="top" title="Paste Commodity">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
              <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
            </svg>
            </button>
          </div>
        </div>


        <div :id="mccompUID" class="accordion-collapse collapse" :aria-labelledby="mhcompUID" :data-bs-parent="hcompUID">
          <div  class="accordion-body" v-for="row in allDataCommodities" v-bind:key="row" :id="row.commodityID"  >
            <div class="row justify-content-start"  >
              <div  class="col-10" style="background-color: #F8D588; color: #5A5C4F">
                Commodity:  {{row.commodityName}}, Total Footprint: {{Math.round((row.commodityBasePrice*row.commodityQty)*100)/100}} kgCO2e
              </div>
              <div class="col-2 my-auto" style="display:flex; justify-content: ">

                <button  v-if="(userRole!== 'client' && wpStatus === 'working') || (userRole === 'admin' || userRole === 'projectmanager' || userRole === 'qamanager')"  type="button" class="btn btn-link btn-sm" @click="fetchDataCommodity(row.commodityID)" value="Edit" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Commodity">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                  </svg>

                </button>
                <button v-if="$store.state.clipboard.commodityID != row.commodityID && userRole!= 'client'" type="button" class="btn btn-link btn-sm"  @click="clipCommodity(row.commodityID, row.commodityName)" value="Copy" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy Commodity">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkgreen" class="bi bi-clipboard-plus" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                  </svg>
                </button>
                <button v-if="$store.state.clipboard.commodityID == row.commodityID && userRole!= 'client'" type="button" class="btn btn-link btn-sm"    value="Clipped" data-bs-toggle="tooltip" data-bs-placement="top" title="Commodity in clipboard for paste">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="coral" class="bi bi-app-indicator" viewBox="0 0 16 16">
                    <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                    <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                  </svg>
                </button>
                <button v-if="userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="deleteDataCommodity(row.commodityID)" value="Delete" data-bs-toggle="tooltip" data-bs-placement="top" title="Delety Commodity">
                  <svg xmlns="http://www.w3.org/2000/svg"   width="16" height="16" fill="red" class="bi bi-dash-circle my-auto" viewBox="0 0 16 16"  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                  </svg>
                </button>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>




    <div v-if="myModel">
      <transition name="model">
        <div class="modal-mask" v-bind:style="{top: mouseY}" >
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                  <h4 class="modal-title">{{ dynamicTitle }}</h4>
                </div>
                <div class="modal-body">
                  <form class="row g-3 " >
                    <div class="form-group" style="margin-bottom: 20px">

                      <div class="input-group mb-3" style="margin-top: 20px">
                        <span class="input-group-text">Name</span>
                        <input type="text" class="form-control"  v-model="commodityName" required />

                      </div>
                      <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Commodity Type</span>
                      <select class="form-select" aria-label="commodityType"  v-model="commodityType" required>
                        <option selected>C-Part</option>
                        <option value="1">Fastener</option>
                        <option value="2">Consumable (like Paint)</option>
                        <option value="3">Other</option>
                      </select>
                      </div>

                      <div class="input-group mb-3" style="margin-top: 20px">
                      <span class="input-group-text">Unit</span>
                        <select class="form-select" aria-label="commodityUnit"  v-model="commodityUnit" required>
                        <option selected>g</option>
                        <option value="1">kg</option>
                        <option value="2">liter</option>
                        <option value="3">pieces</option>
                      </select>

                      <div class="input-group mb-3" style="margin-top: 20px">
                        <span class="input-group-text">Quantity used</span>
                        <input type="number" class="form-control"   step="0.1" v-model="commodityQty" required />
                      </div>

                      <div class="input-group mb-3" style="margin-top: 20px">
                        <span class="input-group-text">Footprint per Unit</span>
                        <input type="number" class="form-control"   step="0.1" v-model="commodityBasePrice" required />
                        <span class="input-group-text">kgCO2e</span>
                      </div>



                      <div class="input-group mb-3" style="margin-top: 20px">
                        <span class="input-group-text">Total Footprint</span>
                        <input type="number" class="form-control"   min="0" max="500" v-bind:value="calculatedComPrice" readonly />
                        <span class="input-group-text">kgCO2e</span>
                      </div>



                        <div class="input-group mb-3" style="margin-top: 20px">
                          <span class="input-group-text">Remark</span>
                        <input type="text" class="form-control" v-model="commodityRemark" />
                      </div>
                      <br />
                      <div align="center">
                        <input type="hidden"   v-model="procID"  :placeholder="procID">
                        <input type="hidden"   v-model="commodityID">
                        <input type="hidden" v-model="hiddenId" />
                        <input v-if="userRole!= 'qamanager'" type="button" class="btn btn-success btn-xs" v-model="actionButton" @click="submitDataCommodity" />
                      </div>
                </div>
              </div>
             </form>
            </div>
                <div class="modal-footer">
                  <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                </div>
           </div>
          </div>
        </div>
       </div>
      </transition>
    </div>

  </div>
</template>

<script>
const axios = require('axios').default;
// axios.<method> will now provide autocomplete and parameter typings
import { v4 as uuidv4 } from 'uuid';




export  default {
  name: "Commodities",

  props: {
    procID: String,
    wpStatus: String,
    namespace: Array,
  },

  data: function () {
    return {

      allDataCommodities:[], myModel: false, actionButton: 'Insert', dynamicTitle: 'Add Data', WP_ID: '', WP_Name: '',
      projectNo:this.$route.query.projectNo, wpID: this.$route.query.wpID, compUID: '', hcompUID :'' , mcompUID:'', mhcompUID:'', mchcompUID:'', mccompUID:'',
      commodityType: '', commodityName: '', commodityID: '', commodityUnit:'',  commodityQty:'', commodityBasePrice:'', commodityRemark: '',   numbers: [5, 8, 3],
      commodityCostSum: 0, userRole : 'admin', mouseY: 0
    };
  },



  methods: {


    //  MANAGE COMMODITIES
    fetchAllDataCommodities: function () {
     // this.$store.commit('deleteComm', this.procID)

      axios.post('/src/php/sustamizer_collab_manage_commodities.php', {
        action: 'fetchallCommodity', projectNo: this.$route.query.projectNo, wpID: this.$route.query.wpID,
        ceID: this.$parent.$auth.user.sub,
        procID: this.procID,
        userRole: this.$route.query.userRole
      }).then((response) => {
        this.allDataCommodities = response.data;


        // SEND THE DATA TO VUEX
        // empty if no last equipment deleted //
        // PUT DATA TO VUEX

        if (this.allDataCommodities.length > 0) {
          let costSum1 = 0

          costSum1 = this.allDataCommodities.reduce(function (prev, cur) {
            return Math.round((prev + cur.commodityBasePrice * cur.commodityQty) * 100) / 100;
          }, 0);

          costSum1 = Math.round(costSum1 * (1+this.$store.state.project.standardRejectRate) * (1+this.$store.state.project.matOH)*100)/100;
          this.commodityCostSum = costSum1



          if (costSum1 != 0 ) {
            this.$store.commit('addComm', {
              commodityID: '',
              procID: this.procID,
              commodityCost: costSum1
            });
          }

        }
        })






      // console.log(response.data);
    },

    openModelCommodity: function (procID) {
      this.commodityName = '';
      this.commodityType = '';
      this.commodityRemark = '';
      this.commodityUnit = '';
      this.commodityQty = '';
      this.commodityBasePrice = '';
      this.commodityID = '';
      this.procID = procID;
      this.actionButton = "Save";
      this.dynamicTitle = "Add Commodity";
      this.mouseY = event.clientY+'px';
      this.myModel = true;
    },
    submitDataCommodity: function () {
      if (this.commodityName != '') {
        if (this.actionButton == 'Save') {
          this.myModel = false;
          axios.post('/src/php/sustamizer_collab_manage_commodities.php', {
            action: 'insertCommodity',
            projectNo: this.$route.query.projectNo,
            wpID: this.$route.query.wpID,
            wpHash: this.$route.query.wpHash,
            ceID: this.$parent.$auth.user.sub,
            procID: this.procID,
            commodityID: this.commodityID,
            commodityName: this.commodityName,
            commodityType: this.commodityType,
            commodityRemark: this.commodityRemark,
            commodityBasePrice: this.commodityBasePrice,
            commodityQty: this.commodityQty,
            commodityUnit: this.commodityUnit,


          }).then((response) => {
            parent.myModel = false;
            this.fetchAllDataCommodities();
            // this.commodityName = '';
            // Alert to replace by Toast
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
        if (this.actionButton == 'Update') {
          axios.post('/src/php/sustamizer_collab_manage_commodities.php', {
            action: 'updateCommodity',
            commodityName: this.commodityName,
            commodityType: this.commodityType,
            commodityQty: this.commodityQty,
            commodityUnit: this.commodityUnit,
            commodityBasePrice: this.commodityBasePrice,
            commodityRemark: this.commodityRemark,
            commodityID: this.commodityID,

          }).then((response) => {
            this.myModel = false;
            this.fetchAllDataCommodities();
            // this.commodiyName = '';
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
      } else {
        alert("Please fill in all necessary fields!");
      }
    },
    fetchDataCommodity: function (commodityID) {
      let self = this
      axios.post('/src/php/sustamizer_collab_manage_commodities.php', {
        action: 'fetchSingleCommodity',
        commodityID: commodityID
      }).then(function (response) {
        self.commodityName = response.data.commodityName;
        self.commodityType = response.data.commodityType;
        self.commodityQty = response.data.commodityQty;
        self.commodityUnit = response.data.commodityUnit;
        self.commodityBasePrice = response.data.commodityBasePrice;
        self.commodityRemark = response.data.commodityRemark;
        self.commodityID = response.data.commodityID;
         self.mouseY = '-'+event.clientY+'px';
 self.myModel = true;
        self.actionButton = 'Update';
        self.dynamicTitle = 'Edit Data';
      });
    },
    deleteDataCommodity: function (commodityID) {
      if (confirm("Are you sure you want to remove this data?")) {
        axios.post('/src/php/sustamizer_collab_manage_commodities.php', {
          action: 'deleteCommodity',
          commodityID: commodityID
        }).then((response) => {
          this.fetchAllDataCommodities();
          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
    },


    // ================ COPY PASTE METHOD  ===============================
    clipCommodity: function (commodityID, commodityName) {
      this.$store.state.clipboard.commodityID = commodityID;
      this.$store.state.clipboard.commodityName = commodityName;
     // alert('Copied');
    },


    pasteCommodity: function (commodityID) {


      axios.post('/src/php/sustamizer_collab_manage_commodities.php', {
        action: 'pasteCommodity',
        commodityID: commodityID,
        projectNo: this.$route.query.projectNo,
        wpID: this.$route.query.wpID,
        ceID: this.$parent.$auth.user.sub,
        procID: this.procID,
        wpHash: this.$route.query.wpHash


      }).then((response) => {
        parent.myModel = false;
        this.fetchAllDataCommodities();
        // this.commodityName = '';
        // Alert to replace by Toast
        if ( response.data.message ) {
          // alert(response.data.message);
          response.data.message = '';
        }
      });
    },
  },




    created: function () {
      this.compUID = uuidv4();
      this.compUID = this.compUID.replace(/-/g, '');
      this.compUID = 'a' + this.compUID;
      this.compUID = this.compUID.substring(0, 10);
      this.hcompUID = '#' + this.compUID;
      this.mhcompUID = 'mh' + this.compUID;
      this.mchcompUID = '#mc' + this.compUID;
      this.mccompUID = 'mc' + this.compUID;

      this.fetchAllDataCommodities();
      this.$forceUpdate();



    },


    computed: {
      calculatedComPrice: function () {

        return (Math.round((this.commodityBasePrice * this.commodityQty) * 100) / 100);
      },
      totalCommodityCost() {
        let costSum = 0

        if (this.allDataCommodities.length > 0) {
          costSum = this.allDataCommodities.reduce(function (prev, cur) {
            return Math.round((prev + cur.commodityBasePrice * cur.commodityQty) * 100) / 100;
          }, 0);

          //   this.$emit('commodityToAssembly', costSum);

          return costSum;

        } else {
          return 0
        }
        // Delete old entries



      }
    }
  }

</script>

<style scoped>

.accordion {
  padding-left: 10px;
}


.accordion-header {
  ackground-color: #F2AF1D;
  color: #E7E9DF;
  padding-left: 10px;
}
.accordion-button  {
  ackground-color: #F2AF1D;
  color: #E7E9DF;
  padding-top:5px;
  padding-bottom:5px;
}


table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}

.modal-mask {
   position: absolute;
   z-index: 2000 !important;
   left: 10%;
   transform: translate(0px, -250px);
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, .5);
   display: table;
   transition: opacity .3s ease;
   font-size: medium;
   overflow-y: scroll;
 }

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}


</style>