<template>
<div>


       <div class="accordion accordion-flush" :id="compUID">
            <div class="accordion-item">
              <div class="row justify-content-start" >
                <div class="col-11">
                    <h2 class="accordion-header" :id="mhcompUID">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="mchcompUID" aria-expanded="false" :aria-controls="mccompUID" style="background-color: #D3D6C3; color: #5A5C4F">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="margin-right:10px"  fill="currentColor" class="bi bi-minecart" viewBox="0 0 16 16">
                          <path d="M4 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm8-1a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM.115 3.18A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 14 12H2a.5.5 0 0 1-.491-.408l-1.5-8a.5.5 0 0 1 .106-.411zm.987.82 1.313 7h11.17l1.313-7H1.102z"/>
                        </svg>
                        Materials  [Total Material Footprint: {{totalMaterialCost}} kgCO2e]
                      </button>
                    </h2>
                </div>
                <div class="col-1 my-auto" style="display:flex; justify-content: space-between" >
                  <button v-if="userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="openModelMaterial(partID)" value="Add">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                  </button>
                  <button v-if="$store.state.clipboard.materialID != '' && userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="pasteMaterial($store.state.clipboard.materialID)" value="Paste" data-bs-toggle="tooltip" data-bs-placement="top" title="Paste Material">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                    </svg>
                  </button>

                </div>
            </div>


              <div :id="mccompUID" class="accordion-collapse collapse" :aria-labelledby="mhcompUID" :data-bs-parent="hcompUID"  >
                <div  class="accordion-body" v-for="row in allDataMaterial" v-bind:key="row" :id="row.materialID">
                  <div class="row justify-content-start"  >
                  <div v-if="row.materialPriceUnit === 'g'" class="col-10"  style="background-color: #E7E9DF; color: #5A5C4F">
                    <!-- TASK  MATERIAL REAL PRICE -->
                  Material:  {{row.materialName}}  [weight:  {{row.materialNetWeight}} Material Footprint: {{Math.round((row.materialNetWeight/1200*(1+row.materialYield/100)*(row.materialPrice)*100))/100}}]
                  </div>
                    <div  v-if="row.materialPriceUnit !== 'g'" class="col-10"  style="background-color: #E7E9DF; color: #5A5C4F">
                      <!-- TASK  MATERIAL REAL PRICE -->
                      Material:  {{row.materialName}}  [weight:  {{row.materialNetWeight}} Material Footprint: {{Math.round((row.materialNetWeight/1.2*(1+row.materialYield/100)*(row.materialPrice)*100))/100}}]
                    </div>
                    <div class="col-2 my-auto"  >
                      <button v-if="(userRole!== 'client' && wpStatus === 'working') || (userRole === 'admin' || userRole === 'projectmanager' || userRole === 'qamanager')" type="button" class="btn btn-link btn-sm" @click="fetchDataMaterial(row.materialID)" value="Edit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                      </button>


                      <button v-if="$store.state.clipboard.materialID != row.materialID && userRole!= 'client' " type="button" class="btn btn-link btn-sm"  @click="clipMaterial(row.materialID, row.materialName)" value="Copy" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy Material">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkgreen" class="bi bi-clipboard-plus" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                        </svg>
                      </button>
                      <button v-if="$store.state.clipboard.materialID == row.materialID && userRole!= 'client'" type="button" class="btn btn-link btn-sm"    value="Clipped" data-bs-toggle="tooltip" data-bs-placement="top" title="Material in clipboard for paste">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="coral" class="bi bi-app-indicator" viewBox="0 0 16 16">
                          <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                          <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                        </svg>
                      </button>

                    <button v-if="userRole!= 'client' && wpStatus == 'working'" type="button" class="btn btn-link btn-sm" @click="deleteDataMaterial(row.materialID)" value="Delete">
                      <svg xmlns="http://www.w3.org/2000/svg"   width="16" height="16" fill="red" class="bi bi-dash-circle my-auto" viewBox="0 0 16 16"  >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                      </svg>
                    </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
        </div>




  <div v-if="myModel">
    <transition name="model">
      <div class="modal-mask" v-bind:style="{top: mouseY}" >
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">{{ dynamicTitle }}</h4>
              </div>
              <div class="modal-body">
                <form class="row g-3 " >
             <div class="form-group" style="margin-bottom: 20px">

               <label>Current Material :</label>
               <input type="text" class="form-control" v-model="materialName" readonly><br>
                  <label>Search for Material
                  </label>
               <div class="form-check" >
                 <input class="form-check-input" name="matFamily" type="radio" value="matMet" id="matMet" checked="checked" v-model="materialFamily" @change="resetAutocomplete">
                 <label class="form-check-label" for="matMet">
                   Metals
                 </label>
               </div>
               <div class="form-check" >
                 <input class="form-check-input" name="matFamily" type="radio" value="matPoly" id="matPoly"  v-model="materialFamily" @change="resetAutocomplete">
                 <label class="form-check-label" for="matPoly">
                   Polymers
                 </label>
               </div>
               <div class="form-check" >
                 <input class="form-check-input" name="matFamily" type="radio" value="matComp" id="matComp" v-model="materialFamily" @change="resetAutocomplete">
                 <label class="form-check-label" for="matComp">
                   Composites
                 </label>
               </div>
               <div class="form-check" >
                 <input class="form-check-input" name="matFamily" type="radio" value="matSint" id="matSint" v-model="materialFamily" @change="resetAutocomplete">
                 <label class="form-check-label" for="matSint">
                   Sinter Materials
                 </label>
               </div>
               <div class="form-check" >
                 <input class="form-check-input" name="matFamily" type="radio" value="ownMaterial" id="ownMaterial" v-model="materialFamily" @change="resetAutocomplete">
                 <label class="form-check-label" for="ownMaterial" style="color:blue">
                   Create / Edit Own Material
                 </label>
               </div>

               <div id="matMetSearch" v-if="materialFamily ==='matMet'" style="margin-top: 20px">

               <autocomplete id="matMetSearchAC" :key="autocompleteKey" ref="autocomplete" :search="searchmetal"  placeholder="Search you Material here"   auto-select @submit="handleSubmit"/>

                  <div class="form-check">
                  <input class="form-check-input" name="matType" type="radio" value="matRaw" id="matRaw" checked="checked" v-model="materialType">
                  <label class="form-check-label" for="matRaw">
                    Raw Material
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" name="matType" type="radio" value="matSheet" id="matSheet"  v-model="materialType">
                  <label class="form-check-label" for="matSheet">
                    Material as Sheet
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" name="matType" type="radio" value="matTube" id="matTube" v-model="materialType">
                  <label class="form-check-label" for="matTube">
                    Material as Tube (Round, Rectangular)
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" name="matType" type="radio" value="matBar" id="matBar" v-model="materialType">
                  <label class="form-check-label" for="matBar">
                    Material as Solid (Bar, Slack, Profile)
                  </label>
                </div>
              </div>

               <div  id="matPolySearch" v-if="materialFamily ==='matPoly'" style="margin-top: 20px" >

                 <autocomplete  id="matPolySearchAC" :key="autocompleteKey" ref="autocomplete" :search="searchpoly"  placeholder="Search you Material here"   auto-select @submit="handleSubmit"/>

                 <div class="form-check">
                   <input class="form-check-input" name="matPolyType" type="radio" value="" id="matPolyRaw" checked="checked" v-model="materialType">
                   <label class="form-check-label" for="matPolyRaw">
                     Polymer as Granulate
                   </label>
                 </div>
                 <div class="form-check">
                   <input class="form-check-input" name="matPolyType" type="radio" value="" id="matPolyBlock"  v-model="materialType">
                   <label class="form-check-label" for="matPolyBlock">
                     Polymer as Block
                   </label>
                 </div>
                 <div class="form-check">
                   <input class="form-check-input" name="matPolyType" type="radio" value="" id="matPolyFoil" v-model="materialType">
                   <label class="form-check-label" for="matPolyFoil">
                     Polymer as Foil or Plate
                   </label>
                 </div>
               </div>

               <div id="matCompSearch" v-if="materialFamily ==='matComp'" style="margin-top: 20px">

                 <autocomplete id="matCompSearchAC" :key="autocompleteKey" ref="autocomplete" :search="searchcomp"  placeholder="Search you Material here"  auto-select @submit="handleSubmit"/>

                 <div class="form-check">
                   <input class="form-check-input" name="matCompType" type="radio" value="" id="matCompRaw" checked="checked" v-model="materialType">
                   <label class="form-check-label" for="matCompRaw">
                     Composite Base Material
                   </label>
                 </div>
                 <div class="form-check">
                   <input class="form-check-input" name="matCompType" type="radio" value="" id="matCompSemi"  v-model="materialType">
                   <label class="form-check-label" for="matCompSemi">
                     Semifinished Composite (Block, Plate)
                   </label>
                 </div>
               </div>


               <div id="matSintSearch" v-if="materialFamily ==='matSint'" style="margin-top: 20px">

                 <autocomplete id="matSintSearchAC" :key="autocompleteKey" ref="autocomplete" :search="searchsint"  placeholder="Search you Material here"  auto-select @submit="handleSubmit"/>

                 <div class="form-check">
                   <input class="form-check-input" name="matSintType" type="radio" value="" id="matSintRaw" checked="checked" v-model="materialType">
                   <label class="form-check-label" for="matSintRaw">
                     Sinter Powder
                   </label>
                 </div>
               </div>


               <div  v-if="materialFamily ==='ownMaterial'" style="margin-top: 20px">

                 <div class="input-group mb-3" style="margin-top: 20px">
                   <span class="input-group-text">Material Name</span>
                   <input type="text" class="form-control"    v-model="materialName" required />
                 </div>
                 <div class="input-group mb-3" style="margin-top: 20px">
                   <span class="input-group-text">Material Type</span>
                   <select class="form-select" aria-label="Select Material Group" v-model="materialGroup" required style="color:blue">
                     <option  :value="materialGroup" selected>{{ materialGroup }}</option>
                     <option value="Polymer" >Polymer</option>
                     <option value="Metal" >Metal</option>
                     <option value="Composite" >Composite</option>
                     <option value="Fabric" >Fabric</option>
                     <option value="Leather" >Leather</option>
                     <option value="Other" >Other</option>
                   </select>
                 </div>
                 <div class="input-group mb-3" style="margin-top: 20px">
                   <span class="input-group-text">Material Footprint per Unit</span>
                   <input type="number" class="form-control"   min="0" max="500" v-model="materialPrice" required  />
                   <span class="input-group-text">kgCO2e/{{materialPriceUnit}}</span>
                 </div>
               </div>


                <div class="input-group mb-3" style="margin-top: 20px">
                  <span class="input-group-text">Net Part Qty (Unit)</span>
                  <input type="number" class="form-control"   step="0.1" v-model="materialNetWeight" required />
                  <select  v-if="materialFamily ==='ownMaterial'" class="form-select" aria-label="Material Price Unit" v-model="materialPriceUnit" required style="color:blue; width: 5%">
                    <option  :value="materialPriceUnit" selected>{{ materialPriceUnit }}</option>
                    <option value="kg" >kg</option>
                    <option value="g" >g</option>
                    <option value="sqm" >sqm</option>
                    <option value="liter" >liter</option>
                    <option value="cbm" >cbm</option>
                  </select>
                </div>

                <div class="input-group mb-3" style="margin-top: 20px">
                  <span class="input-group-text">Additional Process Material</span>
                  <input type="number" class="form-control"   min="0" max="500" v-model="materialYield" required @keydown.enter="calculatedGrossWeight" />
                  <span class="input-group-text">%</span>
                </div>

               <div class="input-group mb-3" style="margin-top: 20px">
                 <span class="input-group-text">Gross Part Qty </span>
                 <input type="number" class="form-control"   min="0" max="500" v-bind:value="calculatedGrossWeight" readonly />
                 <span class="input-group-text">{{materialPriceUnit}}</span>
               </div>

                <div class="form-group" style="margin-top: 20px">
                  <label>Remark to Material (Please Source)</label>
                  <input type="text" class="form-control" v-model="materialRemark" />
                </div>
                <br />
                <div align="center">
                  <input type="hidden"   v-model="partID"  :placeholder="partID">
                  <input type="hidden"   v-model="materialID">
                  <input type="hidden" v-model="hiddenId" />
                  <input v-if="userRole!= 'qamanager'" type="button" class="btn btn-success btn-xs" v-model="actionButton" @click="submitDataMaterial" />
                </div>
                 </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="close" @click="myModel=false"><span aria-hidden="true">&times;</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</div>
  </template>

<script>
const axios = require('axios').default;
// axios.<method> will now provide autocomplete and parameter typings
import { v4 as uuidv4 } from 'uuid';
import materialsMetal from '../assets/datalists/materials_metals.json'
import materialsPolymer from '../assets/datalists/materials_polymers.json'
import materialsComposites from '../assets/datalists/materials_composites.json'
import materialsSinter from '../assets/datalists/materials_sinterpowder.json'

// import the component
import Autocomplete from '@trevoreyre/autocomplete-vue'

// import the style
import '@trevoreyre/autocomplete-vue/dist/style.css'

 // let countries = ['Germany','France'];

export  default {
  name: "Material",
   components: {Autocomplete},
  props: {
    partID: String,
    wpStatus: String,
    namespace: Array,



  },

  data: function () {
    return {

      allDataMaterial:{}, myModel: false, actionButton: 'Insert', dynamicTitle: 'Add Data', WP_ID: '', WP_Name: '',
      projectNo:this.$route.query.projectno, wpID: this.$route.query.wpID, compUID: '', hcompUID :'' , mcompUID:'', mhcompUID:'', mchcompUID:'', mccompUID:'',
      materialType: '', materialName:'', materialRemark: '', materialID: '', materialPrice: 0 ,  materialPriceUnit:'kg', materialNetWeight:'',
      materialYield:'', treeMaterialName:'', materialFamily:'',  autocompleteKey: 0, materialGroup:'',userRole : 'admin',
      mouseY: 0
      };
  },



  methods: {


    getMaterialCostTrigger() {
      this.trigger++;

    },



    // Empty MatList in Modal when open
   resetAutocomplete() {
     this.autocompleteKey += 1;
   },


    //  GET MATERIALNAMES
    searchmetal(input) {

      if (input.length < 1) { return [] }
      return materialsMetal.filter(materialmet => {
        //  IF STATEMENT FOR ADDITONAL  SEARCH LIKE FOR MACHINES
            return materialmet.toLowerCase()
                .includes(input.toLowerCase())
      })
    },
    searchpoly(input) {

      if (input.length < 1) { return [] }
      return materialsPolymer.filter(materialpoly => {
        //  IF STATEMENT FOR ADDITONAL  SEARCH LIKE FOR MACHINES
        return materialpoly.toLowerCase()
            .includes(input.toLowerCase())
      })
    },
    searchcomp(input) {

      if (input.length < 1) { return [] }
      return materialsComposites.filter(materialcomp => {
        //  IF STATEMENT FOR ADDITONAL  SEARCH LIKE FOR MACHINES
        return materialcomp.toLowerCase()
            .includes(input.toLowerCase())
      })
    },
    searchsint(input) {

      if (input.length < 1) { return [] }
      return materialsSinter.filter(materialsint => {
        //  IF STATEMENT FOR ADDITONAL  SEARCH LIKE FOR MACHINES
        return materialsint.toLowerCase()
            .includes(input.toLowerCase())
      })
    },
    handleSubmit(result) {
   //   alert(`You selected ${result}`)
      this.treeMaterialName = result
    },

    //  MANAGE MATERIAL
    fetchAllDataMaterial: function () {
      axios.post('/src/php/sustamizer_collab_manage_material.php', {
        action: 'fetchallMaterial', projectNo: this.$route.query.projectno, wpID: this.$route.query.wpID,
        ceID: this.$parent.$auth.user.sub,
        partID: this.partID,
        userRole : this.$route.query.userRole
      }).then((response) => {
        this.allDataMaterial = response.data;


      // console.log(response.data);

      });
    },

    openModelMaterial: function (partID) {
      this.materialName = '';
      this.materialType = 'Metal';
      this.materialGroup = '';
      this.materialPriceUnit = 'kg';
      this.materialPrice= 0;
      this.materialRemark = '';
      this.materialNetWeight = '',
      this.materialYield = '',
      this.partID = partID;
      this.actionButton = "Save";
      this.dynamicTitle = "Add Material";
      this.mouseY = event.clientY+'px';
      this.myModel = true;
    },
    submitDataMaterial: function () {
      if (this.treeMaterialName != ''  || this.materialName) {
         if (this.treeMaterialName != '' && this.materialName == '') {
           this.materialName = this.treeMaterialName;
         }
       if (this.actionButton == 'Save') {
        this.myModel = false;
      //   console.log("This Treevalue: " + this.treeMaterialName);
        axios.post('/src/php/sustamizer_collab_manage_material.php', {
          action: 'insertMaterial',
          projectNo: this.$route.query.projectNo,
          wpID: this.$route.query.wpID,
          wpHash: this.$route.query.wpHash,
          ceID: this.$parent.$auth.user.sub,
          partID: this.partID,
          materialID: this.materialID,
          materialPrice: this.materialPrice,
          materialPriceUnit: this.materialPriceUnit,
          materialName: this.materialName,
          materialType: this.materialType,
          materialGroup: this.materialGroup,
          materialRemark: this.materialRemark,
          materialNetWeight: this.materialNetWeight,
          materialYield: this.materialYield,
          materialFamily: this.materialFamily


        }).then( (response)=>{
          parent.myModel = false;
          this.fetchAllDataMaterial();
          // this.materialName = '';

          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
        if (this.actionButton == 'Update') {

          axios.post('/src/php/sustamizer_collab_manage_material.php', {
            action: 'updateMaterial',
            materialFamily: this.materialFamily,
            materialPrice: this.materialPrice,
            materialPriceUnit: this.materialPriceUnit,
            materialName: this.materialName,
            materialType: this.materialType,
            materialGroup: this.materialGroup,
            materialNetWeight: this.materialNetWeight,
            materialYield: this.materialYield,
            materialRemark: this.materialRemark,
            materialID: this.materialID,

          }).then((response)=>{
            this.myModel = false;
            this.fetchAllDataMaterial();
            // this.materialName = '';
            if ( response.data.message ) {
              // alert(response.data.message);
              response.data.message = '';
            }
          });
        }
      } else {
        alert("Please fill in all necessary fields!");
      }
    },
    fetchDataMaterial: function (materialID) {
      let self = this
      axios.post('/src/php/sustamizer_collab_manage_material.php', {
        action: 'fetchSingleMaterial',
        materialID: materialID
      }).then(function (response) {
        self.materialName = response.data.materialName;
        self.materialPrice = response.data.materialPrice;
        self.materialPriceUnit = response.data.materialPriceUnit;
        self.materialType = response.data.materialType;
        self.materialGroup = response.data.materialGroup;
        self.materialNetWeight = response.data.materialNetWeight;
        self.materialYield = response.data.materialYield;
        self.materialRemark = response.data.materialRemark;
        self.materialID = response.data.materialID;
         self.mouseY = '-'+event.clientY+'px';
 self.myModel = true;
        self.actionButton = 'Update';
        self.dynamicTitle = 'Edit Data';
      });
    },
    deleteDataMaterial: function (materialID) {
      if (confirm("Are you sure you want to remove this data?")) {
        axios.post('/src/php/sustamizer_collab_manage_material.php', {
          action: 'deleteMaterial',
          materialID: materialID
        }).then( (response) => {
          this.fetchAllDataMaterial();
          if ( response.data.message ) {
            // alert(response.data.message);
            response.data.message = '';
          }
        });
      }
    },


    // ================ COPY PASTE METHOD  ===============================
    clipMaterial: function (materialID, materialName) {
      this.$store.state.clipboard.materialID = materialID;
      this.$store.state.clipboard.materialName = materialName;
      // alert('Copied');
    },


    pasteMaterial: function (materialID) {


      axios.post('/src/php/sustamizer_collab_manage_material.php', {
        action: 'pasteMaterial',
        materialID: materialID,
        projectNo: this.$route.query.projectNo,
        wpID: this.$route.query.wpID,
        ceID: this.$parent.$auth.user.sub,
        partID: this.partID,
        wpHash: this.$route.query.wpHash

      }).then((response) => {
        parent.myModel = false;
        this.fetchAllDataMaterial();
        // this.commodityName = '';
        // Alert to replace by Toast
        if ( response.data.message ) {
          // alert(response.data.message);
          response.data.message = '';
        }
      });
    }

  },


  created:function(){
    this.compUID = uuidv4();
    this.compUID = this.compUID.replace(/-/g,'');
    this.compUID = 'a'+this.compUID;
    this.compUID = this.compUID.substring(0,10);
    this.hcompUID = '#'+this.compUID;
    this.mhcompUID = 'mh'+this.compUID;
    this.mchcompUID = '#mc'+this.compUID;
    this.mccompUID = 'mc'+this.compUID;

    this.fetchAllDataMaterial();
    // FALL BACK IF Compute is no reactive
    this.getMaterialCostTrigger();
    this.timer = setInterval(this.getMaterialCostTrigger, 5000000);
  },

  computed: {
    calculatedGrossWeight: function() {
      return (Math.round((this.materialNetWeight*(1+this.materialYield/(100)))*100)/100)
    },
    totalMaterialCost () {
      let costSum = 0
      let unitFactor = 0
      // return this.numbers.reduce((sum, val)=> sum + val);
      if (this.allDataMaterial.length > 0 ) {
        costSum = this.allDataMaterial.reduce(function (prev, cur) {
      // TAKE FINAL PRICE !!
          switch (cur.materialPriceUnit) {
            case 'g':
              unitFactor = 1000;
              break;
            case 'kg':
              unitFactor = 1;
              break;
            case 'sqm':
              unitFactor = 1;
              break;
            case 'cbm':
              unitFactor = 1000;
              break;
            case 'liter':
              unitFactor = 1000;
              break;
          }
         // AR CONVERT TO CO /1200
          return Math.round((prev + (cur.materialNetWeight/unitFactor*(1+cur.materialYield/100)*(cur.materialPrice))/1200)*10)/10;
        }, 0);

        if (this.trigger > 5) {
          costSum = Math.round((costSum + this.trigger) * 100) /100;
          costSum = Math.round((costSum - this.trigger) * 100) / 100;
          costSum = Math.round(costSum* 100) /100;
        }
       // costSum = costSum + Math.round(costSum * (1+this.$store.state.project.standardRejectRate) *(1+this.$store.state.project.matOH)*100)/100;



          this.$store.commit('addMat', {
            materialID: '',
            partID: this.partID,
            wpID: this.wpID,
            materialCost: costSum
          });

        return costSum;
      }
      else {
        return 0
      }
    }
  }

}
</script>

<style scoped>

.accordion {
  padding-left: 10px;
}

.accordion-header {
  background-color: #D3D6C3;
  color: #5A5C4F;
  padding-left: 10px;
}
.accordion-button  {
  background-color: #D3D6C3;
  color: #5A5C4F;
  padding-top:5px;
  padding-bottom:5px;
}


table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}

.modal-mask {
   position: absolute;
   z-index: 2000 !important;
   left: 10%;
   transform: translate(0px, -250px);
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, .5);
   display: table;
   transition: opacity .3s ease;
   font-size: medium;
   overflow-y: scroll;
 }

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  font-size: small;
}


</style>