<template>
  <div id="app" class="d-flex flex-column h-100">
    <nav-bar  />



    <div class=" w-100  ">
      <error />
      <div class="mt-5">
        <router-view />
      </div>
    </div>



    <footer class="bg-light text-center " style="font-size: small; height:20px; margin: 0;">
        sustamizer® Collab App a Service of
        <a href="https://www.cardo.ag" target="_blank">worldwatcher</a>  &


    </footer>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import Error from "./components/Error";


export default {
  components: {
    NavBar,
    Error,

  },




};
</script>

<style>

body  {
  background-color: #F2F2F2;
}




</style>