<template>
  <div class="container" style="padding-top: 100px">
    <div class="row align-items-center profile-header">
      <div class="col-md-2 mb-3">
        <div v-for="(value, index) in namespace" v-bind:key="value">
          <div v-if="index==='https://www.sustamizer.net/picture'"><img
              v-bind:src="value"
              alt="User's profile picture"
              class="rounded-circle img-fluid profile-picture" width="200" v-bind:key="value"
          /></div>
        </div>
      </div>
      <div class="col-md text-center text-md-left">
        <h2>{{ $auth.user.name }}</h2>
        <p class="lead text-muted">Mail: {{ $auth.user.email}}</p>
        <div v-for="(value, index) in namespace" v-bind:key="value">
        <div v-if="index==='https://www.sustamizer.net/role' && value==='admin'"><h1 style="color:red">ADMIN</h1>
        </div>
      </div>
    </div>
    </div>

    <div class="row">
      <highlightjs autodetect :code="JSON.stringify($auth.user, null, 2)" class="rounded w-100" />
    </div>
  </div>

</template>

<script>
export  default {
  name: "profile",
  data () {
    return {
      namespace : (this.$parent.$auth.user)
    }
  },
}
</script>