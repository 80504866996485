<template>
  <div class="text-center" style="align-content:center; text-align:center;width: 80%; margin-top: 100px; margin-left: 10%; margin-right: 10%">
    <div style="padding-top: 50px"></div>
    <h1 >sustamizer® Product Carbon Footprint Analyser Tool</h1>
    <p style="font-size:larger" >
      The sustamizer® Product Carbon Footprint Analyser tool is a framework for collaborative and remote product footprint analysis in teams and provides a full range of
      functionality to model products manufacturing  processes.
      <a href="https://www.sustamizer.net" >Powered by worldwatchers GmbH</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Hero"
};
</script>
