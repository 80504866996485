// store/index.js

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    versionControl: {
        version: '',
        releaseDate: '',
        releaseNote:  '',
    },
    state: {
      project:
          {
          projectNo:'CA250051',
          annualVol: 900000,
          numberLots: 12,
          lotSize: 75000,
          batchSize: 75000,
          matOH: 0.035,
          procOH: 0.20,
          logOH: 0.02,
          sgaOH: 0.12,
          marginElectronics:0.10,
          marginMaterial: 0.06,
          marginAddedValue: 0.06,
          standardLocation: 'Mexico',
          baseCurrency: 'EUR',
          standardRejectRate: 0.005,
          depreciationTimeMechanical: 10,
          depreciationTimeElectronics: 7,
          depreciationTimeAssembly:7,
          interestRate: 0.04,
          insuranceRate: 0.02,
          yearlyWageIncrease: 0.02,
          workingDays: 240,
          shiftModel: 3,
          hoursPerShift: 7.5,
          utilizationRate: 0.85,
          availability: 0.85,
          },

      commodity: [
         {
         commodityID: '',
         procID: '',
         commodityCost: 0,

     }
     ],
      material: [
            {
                materialID: '',
                wpID:'',
                partID: '',
                materialCost: 0,
            }
        ],
      equipment: [
            {
                equipmentID: '',
                procID:'',
                equipmentCost: '',
            }
        ],
      process: [
            {
                procID: '',
                procCost: '',
            }
        ],
        assyProcessSum: [
            {
                wpID: '',
                procID: '',
                subassyID: '',
                assyProcCostSum: '',
            }
        ],
        processSum: [
            {
                wpID: '',
                procID: '',
                partID: '',
                procCostSum: '',
            }
        ],
        wpSum: [
            {
                wpID: '',
                projectNo: '',
                subassyID: '',
                wpTotalCostSum: 0,
            }
        ],
        partCost: [
            {
                wpID: '',
                projectNo: '',
                partCost: 0,
            }
        ],
        partSumCost: [
            {
                wpID: '',
                projectNo: '',
                subassyID: '',
                partTotalCost: 0,
            }
        ],


      clipboard: {
          commodityID: '',
          commodityName: '',
          processID:  '',
          processName: '',
          assyprocessID:  '',
          assyprocessName: '',
          partID: '',
          partName: '',
          equipmentID: '',
          equipmentName: '',
          materialID: '',
          materialName: '',
      },
        ownEquip: [
            {
                UPPID: 'test',
                equipDesc: 'test',
            }
        ],


     },
    getters: {
    //    commodityCost: state => state.commodity,
    //   processCost: state => state.process,
    //    equipmentMHR: state => state.equipment
    },
    mutations: {
        addComm (state, commodityVal) {
            const existsCommodity = state.commodity.findIndex ( element => element.procID === commodityVal['procID'])
            if (existsCommodity !==-1) {
                state.commodity[existsCommodity].commodityCost = commodityVal['commodityCost']
            }
            else  {
            state.commodity.push(commodityVal)
            }
        },
        deleteComm (state, commDel)  {
            let index = state.commodity.findIndex(element => element.procID === commDel);
            state.commodity.splice(index, 1);
        },

        addProc (state, processVal) {
            state.process.push(processVal)
        },
        addAssyProcSum  (state, assyProcSumVal) {
            const existsAssyProcSum = state.assyProcessSum.findIndex ( element => element.procID === assyProcSumVal['procID'] && element.wpID === assyProcSumVal['wpID']
                && element.subassyID === assyProcSumVal['subassyID'])
            if (existsAssyProcSum !==-1) {
                state.assyProcessSum[existsAssyProcSum].assyProcCostSum = assyProcSumVal['assyProcCostSum']
            }
            else {
                state.assyProcessSum.push(assyProcSumVal)
            }
        },
        addProcSum  (state, procSumVal) {
            const existsProcSum = state.processSum.findIndex ( element => element.procID === procSumVal['procID'] && element.wpID === procSumVal['wpID']
            &&  element.partID === procSumVal['partID'])
            if (existsProcSum !==-1) {
                state.processSum[existsProcSum].procCostSum = procSumVal['procCostSum']
            }
            else {
                state.processSum.push(procSumVal)
            }
        },
        addWpSum  (state, wpSumVal) {
            const existsWPSum = state.wpSum.findIndex ( element => element.projectNo === wpSumVal['projectNo'] && element.wpID === wpSumVal['wpID']
                &&  element.subassyID === wpSumVal['subassyID']
               )
            if (existsWPSum !==-1) {
                state.wpSum[existsWPSum].wpTotalCostSum = wpSumVal['wpTotalCostSum']
            }
            else {
                state.wpSum.push(wpSumVal)
            }
        },
        delWpSum (state, equipDel)  {
            for (let i=0; i< state.wpSum.length; i++) {
                let index = state.wpSum.findIndex(element => element.projectNo === equipDel['projectNo']);
                state.wpSum.splice(index, 1);
            }
        },
        addPartCost  (state, partCostVal) {
            const existsPartCost = state.partCost.findIndex ( element => element.procID === partCostVal['procID'] && element.wpID === partCostVal['wpID'])
            if (existsPartCost !==-1) {
                state.partCost[existsPartCost].partCost = partCostVal['partCost']
            }
            else {
                state.partCost.push(partCostVal)
            }
        },
        addPartSumCost  (state, partSumCostVal) {
            const existsPartSumCost = state.partSumCost.findIndex ( element =>  element.wpID === partSumCostVal['wpID'] && element.projectNo === partSumCostVal['projectNo']
                && element.subassyID === partSumCostVal['subassyID'])
            if (existsPartSumCost !==-1) {
                state.partSumCost[existsPartSumCost].partTotalCost = partSumCostVal['partTotalCost']
            }
            else {
                    state.partSumCost.push(partSumCostVal)
                }

        },
        addEquip  (state, equipmentVal) {
            const existsEquipment = state.equipment.findIndex ( element => element.procID === equipmentVal['procID'])
            if (existsEquipment !==-1) {
                state.equipment[existsEquipment].equipmentCost = equipmentVal['equipmentCost']
            }
            else {
                state.equipment.push(equipmentVal)
            }
        },
        deleteEquip (state, equipDel)  {
            let index = state.equipment.findIndex(element => element.procID === equipDel);
            state.equipment.splice(index, 1);
        },
        addMat (state, matVal) {
            const existsMaterial = state.material.findIndex ( element => element.partID === matVal['partID'])
            if (existsMaterial !==-1) {
                state.material[existsMaterial].materialCost = matVal['materialCost']
            }
            else {
                state.material.push(matVal)
              }
        },
        deleteMat (state, partIDdel)  {
            let index = state.material.findIndex(partID => partID.id === partIDdel);
            state.material.splice(index, 1);
        },
        addOwnEquip (state, ownEquip) {
            state.ownEquip.push(ownEquip)
        },


    },
    actions: {}
});